import React from 'react';
// import { hot } from 'react-hot-loader/root';

import Routes from './Routes';

import './styles/bootstrap.css';
import './styles/global.css';

const App: React.FunctionComponent = () => (
  <div className="body">
    <Routes />
  </div>
);

export default /* process.env.ENABLE_HOT_RELOADING ? hot(App) : */ App;
