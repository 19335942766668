import React, { FunctionComponent } from 'react';

const RibbonIcon: FunctionComponent<any> = () => {
  return (
    <svg
      width="78"
      height="78"
      viewBox="0 0 78 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ margin: 'auto auto -39px auto' }}
    >
      <rect width="78" height="78" rx="16" fill="#6141A9" />
      <path
        d="M51.0294 22.2362C48.0587 20.2006 45.088 19.0032 41.6222 18.5242V15.8898C41.6222 14.3332 40.2605 13.0161 38.6514 13.0161C37.0423 13.0161 35.6807 14.3332 35.6807 15.8898V18.4045C28.749 19.2427 24.0454 23.7927 24.1691 29.6601C24.1691 34.6892 26.6447 37.9224 32.2148 39.958L39.8892 36.0065L39.1466 35.8867C33.2051 34.5696 30.1106 33.1327 30.1106 29.4207C30.1106 26.0679 33.3289 23.7929 37.7849 23.7929C41.4983 23.7929 44.469 24.7507 47.6873 27.0258C48.1824 27.3851 48.8014 27.5048 49.4203 27.5048C51.0294 27.5048 52.2672 26.1876 52.2672 24.631C52.2672 23.3138 51.4007 22.5954 51.0294 22.2362Z"
        fill="white"
      />
      <path
        d="M53.9999 47.8611C53.9999 43.0714 51.4005 39.8383 46.078 37.8027L38.5274 41.7543L39.3938 41.9937C47.3157 43.7898 48.0584 45.9452 48.0584 48.3401C48.0584 51.9323 44.8402 54.3271 39.889 54.3271C35.1853 54.3271 31.5957 53.0099 27.7586 50.0163C27.2634 49.5374 26.6445 49.2979 25.9019 49.2979C24.2927 49.2979 23.0549 50.6151 23.0549 52.1717C23.0549 53.1297 23.55 53.9679 24.1689 54.4468C27.7585 57.2009 31.5957 58.8772 35.928 59.4759V62.1103C35.928 63.667 37.2896 64.984 38.8987 64.984C40.5079 64.984 41.8694 63.667 41.8694 62.1103V59.5956C49.0486 58.7575 53.9999 54.2074 53.9999 47.8611Z"
        fill="white"
      />
    </svg>
  );
};

export default RibbonIcon;
