import React, { FunctionComponent } from 'react';
import classnames from 'classnames';

import useWindowSize from '../utils/useWindowSize';

interface TotalCashBackPillProps {
  cashback: string;
}

const TotalCashBackPill: FunctionComponent<TotalCashBackPillProps> = ({
  cashback,
}) => {
  const { isMobile } = useWindowSize();

  return (
    <>
      <p className="text-black tracking-widest">TOTAL CASH BACK</p>
      <div
        id="cashbackPill"
        className={classnames(
          'lightPurple allAroundShadow text-4xl rounded-full py-3 mx-auto my-4',
          {
            'max-w-xs': isMobile,
            'w-max px-12': !isMobile,
          },
        )}
        style={{ border: '1px solid #ebebeb' }}
      >
        {cashback}
      </div>
    </>
  );
};

export default TotalCashBackPill;
