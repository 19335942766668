import React, { FunctionComponent } from 'react';

const RibbonIcon: FunctionComponent<any> = () => {
  return (
    <svg
      width="54"
      height="68"
      viewBox="0 0 54 68"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ margin: 'auto' }}
    >
      <path
        d="M23.9996 6.08902C25.375 3.96366 28.4847 3.96366 29.8601 6.08902C31.1939 8.15015 33.7901 8.99372 36.0807 8.11024C38.4427 7.19923 40.9585 9.02704 40.8219 11.5549C40.6895 14.0064 42.2941 16.2149 44.6665 16.8465C47.1129 17.4979 48.0738 20.4553 46.4775 22.4202C44.9294 24.3256 44.9294 27.0555 46.4775 28.961C48.0738 30.9258 47.1129 33.8833 44.6665 34.5346C42.2941 35.1662 40.6895 37.3747 40.8219 39.8262C40.9585 42.3541 38.4427 44.1819 36.0807 43.2709C33.7901 42.3874 31.1939 43.231 29.8601 45.2921C28.4847 47.4175 25.375 47.4175 23.9996 45.2921C22.6658 43.231 20.0695 42.3874 17.7789 43.2709C15.417 44.1819 12.9012 42.3541 13.0377 39.8262C13.1701 37.3747 11.5656 35.1662 9.19314 34.5346C6.74678 33.8833 5.78584 30.9258 7.38216 28.961C8.93023 27.0555 8.93023 24.3256 7.38216 22.4202C5.78584 20.4553 6.74678 17.4979 9.19314 16.8465C11.5656 16.2149 13.1701 14.0064 13.0377 11.5549C12.9012 9.02704 15.417 7.19923 17.7789 8.11024C20.0695 8.99372 22.6658 8.15015 23.9996 6.08902Z"
        stroke="#EC613C"
        strokeWidth="1.6962"
      />
      <circle
        cx="26.7966"
        cy="25.5574"
        r="13.2617"
        stroke="#EC613C"
        strokeWidth="1.6962"
      />
      <path
        d="M13.7517 42.2641L1.5055 61.7621L9.72741 60.1741L13.1127 67L25.4101 46.0095"
        stroke="#EC613C"
        strokeWidth="1.6962"
        strokeLinejoin="round"
      />
      <path
        d="M29.0146 46.4335L40.888 66.7879L44.7515 60.1907L52.9734 61.7786L40.374 41.7462"
        stroke="#EC613C"
        strokeWidth="1.6962"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RibbonIcon;
