import React, { FunctionComponent } from 'react';

const CashBackIcon: FunctionComponent<any> = () => {
  return (
    <svg
      width="109"
      height="83"
      viewBox="0 0 109 83"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ margin: 'auto auto 10px auto' }}
    >
      <rect
        x="1.5"
        y="17.0308"
        width="86"
        height="49"
        rx="5.5"
        stroke="black"
        strokeWidth="3"
      />
      <rect
        x="7.29807"
        y="33.3498"
        width="87"
        height="51"
        rx="6"
        transform="rotate(-21.6175 7.29807 33.3498)"
        stroke="black"
        strokeWidth="2"
      />
      <rect y="14.5308" width="89" height="53" rx="7" fill="#EFE8FF" />
      <mask
        id="mask0_84_934"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="0"
        y="15"
        width="89"
        height="53"
      >
        <rect y="15.5308" width="89" height="52" rx="7" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0_84_934)">
        <circle cx="5.5" cy="19.0308" r="12.5" stroke="black" strokeWidth="2" />
        <circle cx="5.5" cy="64.0308" r="12.5" stroke="black" strokeWidth="2" />
        <circle
          cx="83.5"
          cy="19.0308"
          r="12.5"
          stroke="black"
          strokeWidth="2"
        />
        <circle
          cx="83.5"
          cy="64.0308"
          r="12.5"
          stroke="black"
          strokeWidth="2"
        />
      </g>
      <circle cx="44" cy="41.5308" r="18" fill="#CECAF2" />
      <rect
        x="1.5"
        y="16.0308"
        width="86"
        height="50"
        rx="5.5"
        stroke="black"
        strokeWidth="3"
      />
    </svg>
  );
};

export default CashBackIcon;
