import React, { FunctionComponent } from 'react';
import classnames from 'classnames';

import useWindowSize from '../utils/useWindowSize';
import { CashBackIcon, CCZoomIcon, DoshIcon, PieIcon } from './Icons';

interface ReviewChartProps {
  topCategory: string;
}

const ReviewChart: FunctionComponent<ReviewChartProps> = ({ topCategory }) => {
  const { isMobile } = useWindowSize();

  return (
    <div>
      <DoshIcon />

      <div
        className={classnames(
          'container max-w-4xl lavenderBackground flex flex-column pt-16',
          {
            'rounded-2xl': !isMobile,
          },
        )}
      >
        <span
          className={classnames(
            'bg-white darkPurple w-max rounded text-sm text-center font-medium tracking-widest px-3 py-1 mb-4 mx-auto',
            {
              'px-4 my-4': isMobile,
            },
          )}
        >
          2021 YEAR IN REVIEW
        </span>
        <p className="darkPurple max-w-lg text-2xl text-center font-bold p-2 mx-auto">
          Together—our Dosh users have generated loads of cash back.
        </p>

        <div
          className={classnames('my-8', {
            'statsTableTopBorder mx-8': !isMobile,
          })}
        >
          <div
            className={classnames('statsTableBottomBorder', {
              'grid grid-cols-3 pt-4': !isMobile,
            })}
          >
            <PieIcon />
            <p
              className={classnames(
                'darkPurple text-lg py-4 m-auto col-span-2 w-full',
                {
                  'text-center px-4': isMobile,
                },
              )}
            >
              {/* eslint-disable-next-line */}
              <span className="font-bold">Top 5%</span> of earners, received{' '}
              <span className="font-bold">$107.84 cash back</span>
            </p>
          </div>
          <div
            className={classnames('grid gap-4', {
              'grid-cols-1': isMobile,
              'grid-cols-2 statsTableBottomBorder': !isMobile,
            })}
          >
            <div
              className={classnames('py-4', {
                statsTableBottomBorder: isMobile,
                statsTableRightBorder: !isMobile,
              })}
            >
              <CCZoomIcon />
              <p className="darkPurple text-lg text-center pt-4">
                {/* eslint-disable-next-line */}
                Over <span className="font-bold">5.6 million</span> transactions
              </p>
            </div>
            <div className="py-4">
              <CashBackIcon />
              <p className="darkPurple text-lg text-center pt-4">
                {/* eslint-disable-next-line */}
                Over <span className="font-bold">$10 million</span> cash back
              </p>
            </div>
          </div>

          <div
            className={classnames('text-center whitespace-nowrap mt-8 mb-4', {
              'flex flex-column': isMobile,
            })}
          >
            <span
              className={classnames(
                'bg-white lightPurple font-medium tracking-widest rounded-l-md px-4 py-2',
                {
                  'rounded-r-md mb-3': isMobile,
                },
              )}
            >
              TOP CATEGORY
            </span>
            <span className="lightPurpleBackground text-white font-medium tracking-wide rounded-md px-3 py-2">
              {topCategory}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewChart;
