import React, { FunctionComponent } from 'react';

const CCZoomIcon: FunctionComponent<any> = () => {
  return (
    <svg
      width="133"
      height="92"
      viewBox="0 0 133 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ margin: 'auto' }}
    >
      <path
        d="M35.5249 34.2075L9.19971 43.7019"
        stroke="#0D0D0D"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M40.2721 79.09L23.0096 85.5634M41.1352 51.4701L23.8728 57.9435C23.1535 57.0804 21.0245 55.4404 18.2625 55.7857C16.5362 56.0015 14.5223 57.0804 13.9468 58.8066C12.6521 62.6907 20.8518 63.9853 21.7149 66.5747C22.5781 69.1641 19.9887 72.185 16.1046 70.8903C12.2206 69.5956 10.0628 68.3009 7.04186 69.5956C4.62511 70.6314 2.00698 71.7534 1 72.185M14.3784 78.6584L19.5571 50.6069"
        stroke="#0D0D0D"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M118.816 16.082L44.1562 43.7019L47.6087 52.3332L121.837 24.2817L118.816 16.082Z"
        fill="#CECAF2"
      />
      <rect
        x="114.283"
        y="1.9241"
        width="47.6128"
        height="79.9086"
        rx="10.0686"
        transform="rotate(69.9019 114.283 1.9241)"
        stroke="#0D0D0D"
        strokeWidth="3"
      />
      <path
        d="M58.2541 59.7038V59.7038C59.1953 62.8749 62.6282 64.5827 65.7253 63.4206L85.5 56.0001"
        stroke="#0078BF"
        strokeLinecap="round"
      />
      <path
        d="M49.7664 25.5766C46.6016 21.261 40.272 12.6297 34.6618 15.2191C29.0515 17.8085 26.4621 35.0709 36.388 60.9646C46.3139 86.8582 59.0862 92.6248 63.5763 90.3107C69.9999 87.0002 67.1727 76.5008 66.5972 70.4589"
        stroke="#0D0D0D"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <circle cx="114.932" cy="39.3863" r="5.17873" fill="#0D0D0D" />
      <circle cx="112.343" cy="40.2495" r="5.17873" fill="#EFE8FF" />
      <circle cx="110.616" cy="41.1123" r="5.17873" fill="#0D0D0D" />
    </svg>
  );
};

export default CCZoomIcon;
