import { useState, useEffect } from 'react';

export interface WindowSize {
  windowWidth: number;
  windowHeight: number;
  isDesktop: boolean;
  isMobile: boolean;
}

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState<WindowSize>({
    windowWidth: document.documentElement.clientWidth,
    windowHeight: document.documentElement.clientHeight,
    isDesktop: document.documentElement.clientWidth >= 768,
    isMobile: document.documentElement.clientWidth < 768,
  });

  // Handler to call on window resize
  const handleResize = () => {
    // Set window width/height to state
    setWindowSize({
      windowWidth: document.documentElement.clientWidth,
      windowHeight: document.documentElement.clientHeight,
      isDesktop: document.documentElement.clientWidth >= 768,
      isMobile: document.documentElement.clientWidth < 768,
    });
  };

  useEffect(() => {
    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
};

export default useWindowSize;
