import React, { FunctionComponent } from 'react';
import classnames from 'classnames';
import { useScreenshot } from 'use-react-screenshot';
import { withBaseIcon } from 'react-icons-kit';
import { facebook as facebookIcon } from 'react-icons-kit/fa/facebook';
import { twitter as twitterIcon } from 'react-icons-kit/fa/twitter';
import { instagram as instagramIcon } from 'react-icons-kit/fa/instagram';
import { whatsapp as whatsappIcon } from 'react-icons-kit/fa/whatsapp';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';

import { metadata } from './Metadata';

import useWindowSize from '../utils/useWindowSize';
import {
  triggerNativeShare,
  supportsMobileShare,
  imageWidth,
} from '../utils/TriggerNativeShare';

interface Props {
  screenshotContainerRef: any;
  cashback: string;
}

const SocialButtons: FunctionComponent<Props> = ({
  screenshotContainerRef,
  cashback,
}) => {
  const { isMobile } = useWindowSize();
  const [image, takeScreenshot] = useScreenshot();

  const SocialMediaIcon = withBaseIcon({
    size: 32,
    style: { color: 'black', cursor: 'pointer' },
    className:
      'allAroundShadow w-max text-center rounded-2xl p-3 mx-auto hover:opacity-75',
  });

  const generateScreenshot = async () => {
    if (!image) {
      const currentPos = window.pageYOffset;
      window.scrollTo(0, 0);
      const docBody: any = document.body;
      const iphoneImage: any = document.getElementById('yearInReviewIphone');
      const yearImage: any = document.getElementById('yearInReviewYear');
      const width = docBody.offsetWidth;

      // eslint-disable-next-line
      docBody.style.width = imageWidth;
      iphoneImage.style.display = 'none';
      yearImage.style.display = 'none';

      const generatedImage = await takeScreenshot(
        screenshotContainerRef.current,
      );

      // eslint-disable-next-line
      docBody.style.width = `${width}px`;
      iphoneImage.style.display = 'block';
      yearImage.style.display = 'block';

      window.scrollTo(0, currentPos);
      triggerNativeShare(generatedImage, cashback);
    } else {
      triggerNativeShare(image, cashback);
    }
  };

  if (supportsMobileShare) {
    return (
      <div
        id="share"
        className={classnames(
          'max-w-xs grid grid-cols-3 gap-4 px-8 pb-8 mx-auto',
          {
            'pt-8': !isMobile,
          },
        )}
      >
        <SocialMediaIcon icon={facebookIcon} onClick={generateScreenshot} />
        <SocialMediaIcon icon={twitterIcon} onClick={generateScreenshot} />
        <SocialMediaIcon icon={instagramIcon} onClick={generateScreenshot} />
      </div>
    );
  }

  return (
    <div
      id="share"
      className={classnames(
        'max-w-xs grid grid-cols-3 gap-4 px-8 pb-8 mx-auto',
        {
          'pt-8': !isMobile,
        },
      )}
    >
      <FacebookShareButton
        url={metadata.currentUrl}
        quote={metadata.description}
        hashtag={`#${metadata.hashtag}`}
        translate={undefined}
      >
        <SocialMediaIcon icon={facebookIcon} />
      </FacebookShareButton>
      <TwitterShareButton
        url={metadata.currentUrl}
        title={metadata.description}
        hashtags={[metadata.hashtag]}
        translate={undefined}
      >
        <SocialMediaIcon icon={twitterIcon} />
      </TwitterShareButton>
      <WhatsappShareButton
        url={metadata.currentUrl}
        title={metadata.description}
        translate={undefined}
      >
        <SocialMediaIcon icon={whatsappIcon} />
      </WhatsappShareButton>
    </div>
  );
};

export default SocialButtons;
