import React, { FunctionComponent } from 'react';
import classnames from 'classnames';

import useWindowSize from '../utils/useWindowSize';

const Footer: FunctionComponent<any> = () => {
  const { isMobile } = useWindowSize();

  return (
    <div className="darkPurpleBackground m-0 py-3 text-white">
      <div
        className={classnames('flex gap-4', {
          'flex-column': isMobile,
          'flex-row justify-content-center': !isMobile,
        })}
      >
        <p
          className={classnames('text-xs', {
            'text-center pb-4': isMobile,
          })}
        >
          Copyright 2021 Dosh Holdings LLC. All Rights Reserved. FAX (512)
          287-3097
        </p>
        <div
          className={classnames('flex gap-4 justify-content-center font-bold', {
            'pb-3': isMobile,
          })}
        >
          <a
            className="underline text-xs"
            href="https://www.dosh.com/privacy-policy"
          >
            Privacy Policy
          </a>
          <a
            className="underline text-xs"
            href="https://www.dosh.com/terms-of-service"
          >
            Terms of Service
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
