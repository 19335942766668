
      import API from "!../../node_modules/style-loader/dist/runtime/injectStylesIntoStyleTag.js";
      import domAPI from "!../../node_modules/style-loader/dist/runtime/singletonStyleDomAPI.js";
      import getTarget from "!../../node_modules/style-loader/dist/runtime/getTarget.js";
      import insertStyleElement from "!../../node_modules/style-loader/dist/runtime/insertStyleElement.js";
      import content, * as namedExport from "!!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js!./global.css";
      
      

var options = {"injectType":"singletonStyleTag","attributes":{"nonce":"ZDAxY2RkYjAtMGM4OS00OGU0LWFjMjQtYmQxMzQ3MmVhNTU1"}};

;
options.setAttributes = function(style, attributes) {
        Object.keys(attributes).forEach((key) => {
          style.setAttribute(key, attributes[key]);
        });
      };
options.insert = function(style){
    var target = getTarget("head");

    if (!target) {
      throw new Error(
        "Couldn't find a style target. This probably means that the value for the 'insert' parameter is invalid."
      );
    }

    target.appendChild(style);
  };
options.domAPI = domAPI;
options.insertStyleElement = insertStyleElement;

var update = API(content, options);



export * from "!!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[0].use[1]!../../node_modules/postcss-loader/dist/cjs.js!./global.css";
       export default content && content.locals ? content.locals : undefined;
