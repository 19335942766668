import React, { FunctionComponent } from 'react';
import { useScreenshot } from 'use-react-screenshot';

import {
  triggerNativeShare,
  supportsMobileShare,
  imageWidth,
} from '../utils/TriggerNativeShare';

interface Props {
  screenshotContainerRef: any;
  cashback: string;
}

const ShareLink: FunctionComponent<Props> = ({
  screenshotContainerRef,
  cashback,
}) => {
  const [image, takeScreenshot] = useScreenshot();

  const generateScreenshot = async () => {
    if (!image) {
      const currentPos = window.pageYOffset;
      window.scrollTo(0, 0);
      const docBody: any = document.body;
      const iphoneImage: any = document.getElementById('yearInReviewIphone');
      const yearImage: any = document.getElementById('yearInReviewYear');
      const width = docBody.offsetWidth;

      // eslint-disable-next-line
      docBody.style.width = imageWidth;
      iphoneImage.style.display = 'none';
      yearImage.style.display = 'none';

      const generatedImage = await takeScreenshot(
        screenshotContainerRef.current,
      );

      // eslint-disable-next-line
      docBody.style.width = `${width}px`;
      iphoneImage.style.display = 'block';
      yearImage.style.display = 'block';

      window.scrollTo(0, currentPos);

      triggerNativeShare(generatedImage, cashback);
    } else {
      triggerNativeShare(image, cashback);
    }
  };

  if (supportsMobileShare) {
    return (
      // eslint-disable-next-line
      <span
        className="lightGreen text-base underline"
        data-html2canvas-ignore="true"
        style={{ cursor: 'pointer' }}
        onClick={generateScreenshot}
      >
        share your stats
      </span>
    );
  }

  return (
    <a
      href="#share"
      className="lightGreen text-base underline"
      data-html2canvas-ignore="true"
    >
      share your stats
    </a>
  );
};

export default ShareLink;
