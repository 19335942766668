import React, { FunctionComponent } from 'react';
import classnames from 'classnames';

import DoshLogo from './DoshLogo';

interface Props {
  isMobile: boolean;
}

const Header: FunctionComponent<Props> = ({ isMobile }) => {
  return (
    <div
      className="max-w-screen-md px-2 py-4 mx-auto"
      style={
        isMobile
          ? {}
          : {
              backgroundImage:
                'url("https://doshdev-assets.s3.amazonaws.com/images/confetti.png")',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }
      }
    >
      <div
        className={classnames('mb-4')}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <DoshLogo height={48} style={{ margin: 'auto' }} />
      </div>

      <img
        className={classnames('mx-auto', {
          'mt-8': isMobile,
          'my-4': !isMobile,
        })}
        style={{ width: isMobile ? '256px' : '324px' }}
        alt="2021"
        src="https://doshdev-assets.s3.amazonaws.com/images/2021Mobile.png"
        id="yearInReviewYear"
      />

      <div
        className={classnames('flex', {
          'flex-column': isMobile,
          'flex-column-reverse': !isMobile,
        })}
      >
        <img
          style={{ marginTop: isMobile ? '-2.5rem' : '1rem' }}
          className="mx-auto"
          alt="iphone"
          src="https://doshdev-assets.s3.amazonaws.com/images/yearInReviewIphone.png"
          data-html2canvas-ignore="true"
          id="yearInReviewIphone"
        />
        <p className="darkPurple max-w-lg text-2xl text-center font-bold mx-auto">
          It&apos;s time to look back at the year and see all that you&apos;ve
          accomplished.
        </p>
      </div>
    </div>
  );
};

export default Header;
