import is from 'is_js';
import { metadata } from '../components/Metadata';
import formatCash from './formatCash';

export const supportsMobileShare = !!navigator.share;
export const imageWidth = '720px';

export const triggerNativeShare = async (image: any, cashback: string) => {
  const overTenDollars = parseInt(cashback, 10) >= 10;
  const content = overTenDollars
    ? `I got ${formatCash(
        cashback,
      )} in cash back in 2021 by using Dosh!  Check it out.`
    : 'I got cash back in 2021 by using Dosh! Check it out.';

  if (supportsMobileShare) {
    fetch(image)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], 'doshYearInReview.png', {
          type: 'image/png',
        });

        const data: any = {
          title: metadata.title,
          text: is.ios() ? `${content} ${metadata.currentUrl}` : content,
          url: metadata.currentUrl,
        };

        if (is.ios()) {
          data.files = [
            new File([file], 'file.png', {
              type: 'image/png',
            }),
          ];
        }

        try {
          // @ts-ignore
          if (!navigator.canShare(data)) {
            throw new Error("Can't share data.");
          }
          navigator.share(data);
        } catch (err) {
          console.error(err);
        }
      });
  }
};
