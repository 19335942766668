import React, { FunctionComponent } from 'react';

const RibbonIcon: FunctionComponent<any> = () => {
  return (
    <div style={{ overflow: 'hidden' }}>
      <svg
        width="168"
        height="180"
        viewBox="0 0 168 180"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ margin: 'auto auto -3rem auto' }}
      >
        <mask id="path-1-inside-1_25_425" fill="white">
          <path d="M125.159 23.653C106.865 13.2819 85.3329 10.1881 64.8583 14.9887C44.3837 19.7892 26.471 32.1313 14.6929 49.5535C2.91479 66.9756 -1.8632 88.1973 1.31185 108.986C4.48691 129.775 15.3817 148.603 31.8233 161.715C48.2649 174.827 69.045 181.26 90.0191 179.73C110.993 178.2 130.62 168.82 144.985 153.461C159.35 138.102 167.397 117.892 167.522 96.8627C167.647 75.8332 159.84 55.5294 145.658 40.0008L83.9385 96.3666L125.159 23.653Z" />
        </mask>
        <path
          d="M125.159 23.653C106.865 13.2819 85.3329 10.1881 64.8583 14.9887C44.3837 19.7892 26.471 32.1313 14.6929 49.5535C2.91479 66.9756 -1.8632 88.1973 1.31185 108.986C4.48691 129.775 15.3817 148.603 31.8233 161.715C48.2649 174.827 69.045 181.26 90.0191 179.73C110.993 178.2 130.62 168.82 144.985 153.461C159.35 138.102 167.397 117.892 167.522 96.8627C167.647 75.8332 159.84 55.5294 145.658 40.0008L83.9385 96.3666L125.159 23.653Z"
          stroke="black"
          strokeWidth="6"
          mask="url(#path-1-inside-1_25_425)"
        />
        <mask
          id="path-2-outside-2_25_425"
          maskUnits="userSpaceOnUse"
          x="65.3158"
          y="-3.91392"
          width="107.635"
          height="133.633"
          fill="black"
        >
          <rect
            fill="white"
            x="65.3158"
            y="-3.91392"
            width="107.635"
            height="133.633"
          />
          <path d="M154.977 31.1919C147.815 23.335 139.449 16.666 130.194 11.4344L80.8066 98.8066L154.977 31.1919Z" />
        </mask>
        <path
          d="M154.977 31.1919C147.815 23.335 139.449 16.666 130.194 11.4344L80.8066 98.8066L154.977 31.1919Z"
          fill="#231B67"
        />
        <path
          d="M154.977 31.1919C147.815 23.335 139.449 16.666 130.194 11.4344L80.8066 98.8066L154.977 31.1919Z"
          stroke="#EFE8FF"
          strokeWidth="21.6135"
          strokeLinejoin="round"
          mask="url(#path-2-outside-2_25_425)"
        />
      </svg>
    </div>
  );
};

export default RibbonIcon;
