import React, { FunctionComponent } from 'react';
import classnames from 'classnames';

interface StatsRowProps {
  IconComponent: any;
  stat: number;
  statLabel: string;
  ContentComponent: any;
  colorClass: string;
  reverse?: boolean;
  isMobile: boolean;
}

const StatsRow: FunctionComponent<StatsRowProps> = ({
  IconComponent,
  stat,
  statLabel,
  ContentComponent,
  colorClass,
  reverse = false,
  isMobile,
}) => {
  return (
    <div className="max-w-2xl mx-auto my-8">
      <div
        className={classnames('flex', {
          'flex-column': isMobile,
          [reverse ? 'flex-row-reverse' : 'flex-row']: !isMobile,
        })}
      >
        <div
          className={classnames(
            'allAroundShadow flex flex-column flex-1 h-56 rounded-2xl',
            { 'py-4': isMobile },
          )}
          style={{ border: '1px solid #ebebeb', borderRadius: '16px' }}
        >
          <div
            className="flex flex-1"
            style={{ display: 'flex', justifyContent: 'center' }}
          >
            <IconComponent />
          </div>
          <div className="flex flex-column flex-1 align-items-center">
            <span className={`${colorClass} text-4xl`}>{stat}</span>
            <span className="text-black text-base">{statLabel}</span>
          </div>
        </div>
        <div style={{ flex: '2' }}>
          <ContentComponent />
        </div>
      </div>
    </div>
  );
};

export default StatsRow;
