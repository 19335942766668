import React, { FunctionComponent } from 'react';

const DoshLogo: FunctionComponent<any> = ({
  color = '#683caf',
  height = 72,
}) => {
  const width = (height * 55) / 18;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 110.783 35.965"
      style={{ margin: 'auto' }}
    >
      <g
        id="Dosh_Logo"
        data-name="Dosh Logo"
        transform="translate(-1.108 -1.113)"
      >
        <path
          id="Path_77"
          d="M9.917,28h-7.9A2.046,2.046,0,0,1,0,25.952V2.049A2.048,2.048,0,0,1,2.015,0h7.9C18.558,0,24.83,5.856,24.831,13.924V14C24.831,22.114,18.559,28,9.917,28ZM4.064,3.829V24.173H9.917c6.318,0,10.73-4.151,10.73-10.1l0-.076c0-5.99-4.412-10.173-10.73-10.173Z"
          transform="translate(1.108 5.042)"
          fill={color}
        />
        <path
          id="Path_78"
          d="M14.295,28.931A14.62,14.62,0,0,1,3.941,24.625,14.527,14.527,0,0,1,0,14.544v-.076A14.589,14.589,0,0,1,3.98,4.347,14.168,14.168,0,0,1,14.182,0h.189l.215,0a14.1,14.1,0,0,1,10.14,4.309,14.528,14.528,0,0,1,3.94,10.081v.077a14.588,14.588,0,0,1-3.979,10.119,14.622,14.622,0,0,1-10.365,4.345Zm.04-25.117A10.177,10.177,0,0,0,4.185,14.388v.076a10.22,10.22,0,0,0,4.922,9.2,10.1,10.1,0,0,0,10.382.04,10.221,10.221,0,0,0,4.991-9.158v-.077a10.226,10.226,0,0,0-4.922-9.2A10.112,10.112,0,0,0,14.335,3.815Z"
          transform="translate(30.198 4.583)"
          fill={color}
        />
        <path
          id="Path_79"
          d="M20.763,0a2.053,2.053,0,0,0-2.054,2.049V12.182H4.068V2.049A2.034,2.034,0,1,0,0,2.049V26.341a2.034,2.034,0,1,0,4.068,0V16.052H18.709V26.341a2.034,2.034,0,1,0,4.068,0V2.049A2.059,2.059,0,0,0,20.763,0Z"
          transform="translate(89.114 4.849)"
          fill={color}
        />
        <path
          id="Path_80"
          d="M18.346,6.3A13.988,13.988,0,0,0,11.92,3.706V2.028a2.016,2.016,0,1,0-4.033,0V3.635C3.212,4.2,0,7.326,0,11.414c0,3.514,1.7,5.753,5.478,7.211L10.685,15.9l-.515-.12c-4.05-.947-6.106-1.912-6.106-4.557,0-2.312,2.166-3.927,5.268-3.927a10.835,10.835,0,0,1,6.725,2.261,2,2,0,0,0,2.057.145,2.021,2.021,0,0,0,1.1-1.754A2.085,2.085,0,0,0,18.346,6.3Z"
          transform="translate(63.625 1.113)"
          fill={color}
        />
        <path
          id="Path_81"
          d="M21.11,7.071c0-3.357-1.714-5.61-5.372-7.071L10.559,2.71l.6.139C16.565,4.1,17,5.606,17,7.306c0,2.487-2.226,4.159-5.539,4.159A11.854,11.854,0,0,1,3.243,8.4a1.877,1.877,0,0,0-1.267-.477A1.961,1.961,0,0,0,.57,8.5,1.984,1.984,0,0,0,0,9.924a1.878,1.878,0,0,0,.789,1.567A16.489,16.489,0,0,0,8.74,15.022v1.811a2.016,2.016,0,1,0,4.033,0V15.139C17.79,14.635,21.11,11.464,21.11,7.071Z"
          transform="translate(62.776 18.217)"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default DoshLogo;
