import React, { FunctionComponent, createRef } from 'react';
import classnames from 'classnames';

import useWindowSize from '../utils/useWindowSize';
import { DecodedJWTPayload } from '../utils/routing';
import formatCash from '../utils/formatCash';
import { CCSwipeIcon, RibbonIcon } from '../components/Icons';

import Metadata from '../components/Metadata';
import Header from '../components/Header';
import TotalCashBackPill from '../components/TotalCashBackPill';
import ShareLink from '../components/ShareLink';
import StatsRow from '../components/StatsRow';
import TopBrands from '../components/TopBrands';
import ReviewChart from '../components/ReviewChart';
import SocialButtons from '../components/SocialButtons';
import Footer from '../components/Footer';

const Review: FunctionComponent<DecodedJWTPayload> = ({
  cash,
  top_brands: topBrandsString,
  num_brands: numBrands,
  purchases,
  ref_code: referralCode,
}) => {
  const screenshotContainerRef = createRef<any>();
  const { isMobile } = useWindowSize();
  const topBrands = topBrandsString.split(',');
  const downloadLink = `https://link.dosh.cash/${referralCode}`;

  return (
    <>
      <Metadata />
      <div
        className={classnames('container mx-auto bg-white', {
          'px-4': !isMobile,
          'px-8': isMobile,
        })}
        id="doshYearInReviewShareSection"
        ref={screenshotContainerRef}
      >
        <Header isMobile={isMobile} />

        <div
          className={classnames('text-center', {
            'py-4': isMobile,
          })}
          style={{
            marginTop: isMobile ? '' : '-3rem',
          }}
        >
          <TotalCashBackPill cashback={formatCash(cash)} />

          <ShareLink
            screenshotContainerRef={screenshotContainerRef}
            cashback={cash}
          />
        </div>

        <StatsRow
          IconComponent={CCSwipeIcon}
          stat={purchases}
          statLabel="PURCHASES"
          ContentComponent={() => (
            <p
              className={classnames(
                'flex align-items-center h-full text-black text-base mx-4',
                { 'text-center': isMobile, 'pt-4': isMobile },
              )}
            >
              Keep using Dosh in 2022 to rack up the cash back! And make sure
              your account is updated with new credit or debit cards.
            </p>
          )}
          colorClass="lightPurple"
          isMobile={isMobile}
        />

        <StatsRow
          IconComponent={RibbonIcon}
          stat={numBrands}
          statLabel="BRANDS"
          ContentComponent={() => (
            <>
              <p
                className={classnames(
                  'flex align-items-center h-2/4 text-black text-base',
                  { 'text-center pt-4': isMobile, 'mr-4': !isMobile },
                )}
              >
                Check the app for all new stores, restaurants and hotels we have
                to offer – we’re always adding new brands and there are more
                coming soon!
              </p>

              <TopBrands brands={topBrands} isMobile={isMobile} />
            </>
          )}
          colorClass="orange"
          isMobile={isMobile}
          reverse
        />
      </div>

      <ReviewChart topCategory="Online Shopping" />

      <div
        className={classnames('py-8', {
          'pb-14': isMobile,
        })}
      >
        <p
          className={classnames(
            'darkPurple max-w-lg text-2xl text-center font-bold mx-auto',
            {
              'p-8': isMobile,
              'p-2': !isMobile,
            },
          )}
        >
          What an amazing year. Why not share your success with friends?
        </p>

        <SocialButtons
          screenshotContainerRef={screenshotContainerRef}
          cashback={cash}
        />

        <p className="max-w-lg text-black text-lg text-center p-2 mx-auto">
          If you haven&apos;t used Dosh in a while-now&apos;s a great time to
          download again and make 2022 even better!
        </p>

        <div className="max-w-xs flex flex-row gap-4 mx-auto pt-4 pb-8">
          <a href={downloadLink}>
            <img
              alt="ios"
              src="https://dosh-cash-res.cloudinary.com/image/upload/v1562953804/emails/common/59a4ea61-2123-494e-8c15-3fd7d1244540.png"
            />
          </a>

          <a href={downloadLink}>
            <img
              alt="android"
              src="https://dosh-cash-res.cloudinary.com/image/upload/v1562953804/emails/common/7f4b95bd-7302-4d91-a8b4-d1af4f2936e4.png"
            />
          </a>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Review;
