import React, { FunctionComponent } from 'react';
import classnames from 'classnames';

interface TopBrandsProps {
  brands: string[];
  isMobile: boolean;
}

const TopBrands: FunctionComponent<TopBrandsProps> = ({ brands, isMobile }) => {
  const topBrands = brands.slice(0, 3).filter(Boolean);

  return (
    <div
      className={classnames({
        'py-4 flex flex-column': isMobile,
        'mr-4': !isMobile,
      })}
    >
      <span className="lavenderBackground lightPurple rounded text-sm text-center font-medium tracking-wider px-2 py-1 mb-2">
        YOUR TOP BRANDS
      </span>
      <div
        className={classnames({
          'grid grid-flow-col gap-3 py-3': !isMobile,
          'flex flex-column': isMobile,
        })}
      >
        {topBrands.map((brand) => (
          <span
            key={brand}
            className="lightPurpleBackground col-span-1 uppercase text-sm text-white text-sm text-center overflow-hidden whitespace-nowrap rounded font-medium tracking-wider px-2 py-1 mb-2"
            style={{ textOverflow: 'ellipsis' }}
          >
            {brand}
          </span>
        ))}
      </div>
    </div>
  );
};

export default TopBrands;
