import React, { FunctionComponent } from 'react';
import Helmet from 'react-helmet';

export const metadata = {
  currentUrl: `${window.location.origin}${window.location.pathname}`,
  title: '2021 Dosh Year in Review',
  image: 'https://doshdev-assets.s3.amazonaws.com/images/yearInReview.png',
  description:
    'Dosh users received over $10 million in cash back in 2021. Get the app that gives you cash back and get off to a great start in 2022.',
  hashtag: 'DoshYearInReview',
};

const Metadata: FunctionComponent<any> = () => {
  return (
    <Helmet>
      <title>{metadata.title}</title>
      <link
        rel="icon"
        href="https://doshdev-assets.s3.amazonaws.com/marketing/favicon.png"
      />
      <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
      <meta property="type" content="website" />
      <meta property="url" content={metadata.currentUrl} />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1, shrink-to-fit=no"
      />
      <meta property="title" content={metadata.title} />
      <meta name="description" content={metadata.description} />
      <meta property="image" content={metadata.image} />
      <meta property="og:title" content={metadata.title} />
      <meta property="og:type" content="website" />
      <meta property="og:hashtag" content={metadata.hashtag} />
      <meta property="og:image" content={metadata.image} />
      <meta property="og:url" content={metadata.currentUrl} />
      <meta property="og:description" content={metadata.description} />
    </Helmet>
  );
};

export default Metadata;
