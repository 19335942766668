import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import decodeJWT from 'jwt-decode';

import { base64decode } from './base64';

interface UrlParams {
  encodedJWT: string;
}

export interface DecodedJWTPayload {
  cash: string;
  purchases: number;
  num_brands: number;
  top_brands: string;
  ref_code: string;
  iat: number;
}

export const injectDecodedJWTPayload = (Component: any) => {
  try {
    const { encodedJWT } = useParams<UrlParams>();
    const jwt = base64decode(encodedJWT);
    const decodedJWT = decodeJWT<DecodedJWTPayload>(jwt);
    return <Component {...decodedJWT} />;
  } catch (error) {
    console.warn(error);
    return <Redirect to="/invalid" />;
  }
};
