import React, { FunctionComponent } from 'react';
import { Switch, BrowserRouter as Router, Route } from 'react-router-dom';

import { injectDecodedJWTPayload } from './utils/routing';
import Review from './scenes/Review';

const RedirectToDosh = () => {
  window.location.href = 'https://www.dosh.com';
  return null;
};

const Routes: FunctionComponent = () => (
  <Router>
    <Switch>
      <Route
        path="/2021/:encodedJWT"
        component={() => injectDecodedJWTPayload(Review)}
      />
      <Route path="/invalid" component={RedirectToDosh} />
      <Route path="*" component={RedirectToDosh} />
    </Switch>
  </Router>
);

export default Routes;
